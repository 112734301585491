import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../src/components/global/layout/Layout';
import Customers from '../components/global/customers/Customers';
import InfoBox from '../components/global/info-box/InfoBox';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import Snippet from '../components/global/snippet/Snippet';
import VerticalSlider from '../components/global/ImageSlider/VerticalSlider';
import InfoCard from '../components/global/InfoCard/InfoCard.component';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import SEO from '../components/global/seo';
import { sliderProps } from '../pages/bot-builder';

import { SectionWrapper } from '../components/mid-market/styles';
import {
	ImageHolder,
	Section,
} from '../components/home-page/about-chatbots/styles';
import { Container } from '../components/global/customers/styles';
import * as S from '../components/demand-generation/styles';
import { InfoCardGrid } from '../components/global/InfoCard/InfoCard.styled';
import {
	SliderContainer,
	SliderWrapper,
} from '../components/customer-support/styles';

const OutboundBotsTemplate = ({ data }) => {
	const {
		section1Title,
		section1Subtitle,
		section1Image,
		section1ButtonText,
		section1ButtonUrl,
		customerTitle,
		customerImages,
		section2Title,
		section2Subtitle,
		section2Image,
		section2InfoCards,
		section3InfoSection,
		section4Title,
		section4Subtitle,
		section4Features,
		section5InfoSection,
		section6Title,
		section6Cards,
		cta,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		noIndex,
		seoJsonSchema,
	} = data.contentfulPageOutboundBots;
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				noIndex={noIndex}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<InfoBox
					image={section1Image}
					title={section1Title}
					subtitle={section1Subtitle.section1Subtitle}
					hideImage="true"
					buttonText={section1ButtonText}
					buttonLink={section1ButtonUrl}
					isHeroHeader
				/>
			</WrapperOne>
			<Customers
				section2Images={customerImages}
				section2Title={customerTitle}
			/>
			<Container>
				<Section>
					<SectionTextBox
						title={section2Title}
						subtitle={section2Subtitle.section2Subtitle}
						largeHeading="true"
					/>
					<Img
						fluid={section2Image.fluid}
						alt={section2Image.title}
						style={{ maxWidth: '927px', margin: '0 auto', width: '100%' }}
					/>
				</Section>
				<SectionWrapper>
					<ImageHolder>
						{section2InfoCards.map(info => (
							<Snippet
								url={info.file.url}
								title={info.title}
								subtitle={info.description}
							/>
						))}
					</ImageHolder>
				</SectionWrapper>
				{section3InfoSection.map(section => (
					<S.SectionWrapper>
						<InfoBox
							image={section.image}
							title={section.title}
							subtitle={section.subtitle.subtitle}
							invert={!section.keepImageOnRight}
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</S.SectionWrapper>
				))}
				<SliderContainer>
					<SectionTextBox
						title={section4Title}
						subtitle={section4Subtitle.section4Subtitle}
					/>
					<SliderWrapper>
						<VerticalSlider
							data={section4Features}
							noBottomPadding={true}
							{...sliderProps}
						/>
					</SliderWrapper>
				</SliderContainer>
				{section5InfoSection.map(section => (
					<S.SectionWrapper>
						<InfoBox
							image={section.image}
							title={section.title}
							subtitle={section.subtitle.subtitle}
							invert={!section.keepImageOnRight}
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</S.SectionWrapper>
				))}
				<SliderContainer>
					<SectionTextBox title={section6Title} largeHeading="true" />
				</SliderContainer>
				<InfoCardGrid style={{ marginTop: '20px' }}>
					{section6Cards.map(card => (
						<InfoCard
							image={card.icon}
							title={card.title}
							subtile={card.description.description}
							linkText={card.linkText}
							linkUrl={card.linkUrl}
						/>
					))}
				</InfoCardGrid>
				<CTAWrapper>
					<MasterCTA cta={cta} />
				</CTAWrapper>
			</Container>
		</Layout>
	);
};

export const query = graphql`
	query($slug: String) {
		contentfulPageOutboundBots(slug: { eq: $slug }) {
			slug
			section1Title
			section1Subtitle {
				section1Subtitle
			}
			section1Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section1ButtonText
			section1ButtonUrl
			customerTitle
			customerImages {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section2Title
			section2Subtitle {
				section2Subtitle
			}
			section2Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section2InfoCards {
				file {
					url
				}
				title
				description
			}
			section3InfoSection {
				keepImageOnRight
				title
				subtitle {
					subtitle
				}
				image {
					title
					description
					file {
						url
						contentType
					}
					fluid {
						...GatsbyContentfulFluid
					}
				}
			}
			section4Title
			section4Subtitle {
				section4Subtitle
			}
			section4Features {
				title
				description
				fluid(maxHeight: 500, maxWidth: 747) {
					...GatsbyContentfulFluid
				}
			}
			section5InfoSection {
				keepImageOnRight
				title
				subtitle {
					subtitle
				}
				image {
					title
					description
					file {
						url
						contentType
					}
					fluid {
						...GatsbyContentfulFluid
					}
				}
			}
			section6Title
			section6Cards {
				title
				description {
					description
				}
				icon {
					file {
						url
					}
					title
				}
				linkUrl
				linkText
			}
			cta {
				type
				title
				subtitle
				primaryButtonText
				primaryButtonUrl
				secondaryButtonText
				secondaryButtonUrl
			}
			seoTitle
			seoDescription
			seoArticle
			noIndex
			seoImage {
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
				title
			}
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;

export default OutboundBotsTemplate;
